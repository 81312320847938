export interface TagInfo {
    tags: string[];
    label?: string;
}

/** Knows how to map push notification message strings to cache tags to be cleared upon receipt.
 */
export class CacheMessageMapper {

    static readonly USERS_PAT = /users/;
    static readonly DNS_ZONE_PAT = /dns-zone:(\S+)/;
    static readonly TRANSACTIONS_PAT = /transactions:(\S+)/;
    static readonly DOMAINS_PAT = /domains:(\S+)/;
    static readonly SETTINGS_PAT = /settings:(\S+)/;

    static map(message: string, pattern: RegExp): TagInfo | undefined {
        const m = message.match(pattern);
        if (m) {
            if (pattern === CacheMessageMapper.USERS_PAT) {
                return {
                    tags: ['users']
                };
            } else if (pattern === CacheMessageMapper.DNS_ZONE_PAT) {
                const zone = m[1];
                return {
                    tags: [`dns-zone-domains:${zone}`],
                    label: zone
                };
            } else if (pattern == CacheMessageMapper.TRANSACTIONS_PAT) {
                return {
                    tags: [
                        'transactions.last_n',
                        'transactions.last_30_days',
                        'transactions.last_60_days'
                    ],
                    label: m[1] // domain name
                }
            } else if (pattern == CacheMessageMapper.DOMAINS_PAT) {
                return  {
                    tags: [],
                    label: m[1] // domain name
                }
            } else if (pattern == CacheMessageMapper.SETTINGS_PAT) {
                return {
                    tags: ['settings'],
                    label: m[1] // user ID
                }
            }
        }
        return undefined;
    }
}
