import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable, of} from "rxjs";
import {ConfirmDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog.component";
import {AlertDialogComponent} from "../dialogs/alert-dialog/alert-dialog.component";
import {tap} from "rxjs/operators";

export type AlertType = 'error'|'warn'|'info'|'debug';

const MIN_WIDTH = 320;
const MAX_WIDTH = 480;

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private alertIsOpen: boolean = false;

    constructor(private dialog: MatDialog) {
    }

    private alertObs(type:AlertType, message: string, title?:string, okLabel?:string): Observable<any> {
        // If an alert dialog is already opened, don't open this one. Let first one have precedence. More complex
        // rules could be implemented if necessary, forcing previous once to be closed, but we don't need it for now.
        if (this.alertIsOpen) {
            console.log("NOT opening alert, another one is already open!");
            return of(null);
        }
        
        // Now open our new dialog
        const ref: MatDialogRef<AlertDialogComponent> = this.dialog.open(AlertDialogComponent,
            {
                minWidth: MIN_WIDTH,
                maxWidth: MAX_WIDTH,
                data: {
                    alertType: type,
                    message: message,
                    title: title,
                    okLabel: okLabel
                }
            });
        this.alertIsOpen = true;
        return ref.afterClosed().pipe(
            tap(() => {
                this.alertIsOpen = false;
            })
        );
    }

    alert(type:AlertType, message: string, title?:string, okLabel?:string) {
        this.alertObs(type, message, title, okLabel).subscribe(() => {
        });
    }

    error(message: string, title?:string, okLabel?:string) {
        this.alert('error', message, title, okLabel);
    }

    warn(message: string, title?:string, okLabel?:string) {
        this.alert('warn', message, title, okLabel);
    }

    warnObs(message: string, title?:string, okLabel?:string): Observable<any> {
        return this.alertObs('warn', message, title, okLabel);
    }

    info(message: string, title?:string, okLabel?:string) {
        this.alert('info', message, title, okLabel);
    }

    infoObs(message: string, title?:string, okLabel?:string): Observable<any> {
        return this.alertObs('info', message, title, okLabel);
    }

    debug(message: string, title?:string, okLabel?:string) {
        this.alert('debug', message, title, okLabel);
    }

    confirm(question?:string, title?:string, yesAnswer?:string, noAnswer?:string): Observable<boolean|undefined> {
        const ref: MatDialogRef<ConfirmDialogComponent, boolean>= this.dialog.open(ConfirmDialogComponent,
            {
                data: {
                    question: question,
                    title: title,
                    yesAnswer: yesAnswer,
                    noAnswer: noAnswer
                }
            });
        return ref.afterClosed();
    }
}
